.question-form{
    background-color: #F4F4F9;
    height: 100%;
    padding-bottom: 30px;
}
.section{
    margin: auto;
    width: 50%;
}
/* .question-title-section{} */
.question-form-top{
    background-color: white;
    border-top: 8px solid skyblue;
    border-radius: 8px;
    padding: 30px 25px;
    /* text-transform: capitalize; */
}
.question-from-top-name{
    box-sizing: border-box;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    line-height: 135%;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #F4F4F9;
    color: black;
    height: 35px;
}
.question-from-top-desc{
    box-sizing: border-box;
    margin-top: 10px;
    font-weight: 400;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #F4F4F9;
    color: black;
    height: 20px;
    font-size: 16px;
    line-height: 18px;
}
.question_boxes{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.add_question{
    background-color: white;
    border-radius: 8px;
    padding: 25px 22px;
    /* text-transform: capitalize; */
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    width: 93%;
    margin-left: 10px;
}
.add_question_top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.question{
    box-sizing: border-box;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    flex: 1;
    line-height: 40px;
    width: 40%;
    border: none;
    outline: none;
    color: black;
    height: 40px;
    margin-right: 10px;
    line-height: 18px;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
}
.question:focus{
    border-bottom: 1px solid skyblue;
    background-color: #F4F4F9;
}
.description{
    box-sizing: border-box;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    flex: 1;
    line-height: 40px;
    width: 100%;
    border: none;
    outline: none;
    color: black;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 20px;
    line-height: 18px;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
}
.description:focus{
    border-bottom: 1px solid skyblue;
    background-color: #F4F4F9;
}
.select{
    height: 35px;
    width: 230px;
    border: 0.5px sloid;
    /* padding: 5px 15px; */
    /* color: black; */
    /* margin-left: 10px; */
    /* margin-right: 10px; */ 
    /* border: 1.5px solid #F4F4F9; */
    /* border-radius: 3px;
    background: transparent; */
}

.add_question_body{
    display: flex;
    align-items: center;
    
}
.text_input{
    outline: none;
    border: none;
    height: 40px;
    width: 490px;
    font-size:13px;
    font-weight: 400;
    letter-spacing: .2px;
    color: #202124;
    border-bottom: .5px solid rgb(236, 235, 235);
}
.text_input:focus{
    border-bottom: 1.5px solid skyblue;
}
.add_footer{
    display: flex;
    justify-content: space-between;
}
.add_question_bottom_left{
    margin-top: 12px;
    /* border-top: 1px solid rgba(118,118,118,0.3); */
    display:flex;
    justify-content: flex-end;
    align-items: center;
}
.add_question_bottom{
    margin-top: 12px;
    /* border-bottom: 1px solid rgba(118,118,118,0.3); */
    display:flex;
    justify-content: flex-end;
    align-items: center;
}
.add_border{
    border-left: 6px solid #4285f4;
}
.question_edit{
    background-color: #F4F4F9;
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    margin-top: 9vh;
    margin-bottom: 0;
    padding: 1px;
    border-radius: 3px;
    height: 5vh;
    width: 5vh;
}
.edit{
    color: #5f6368;
    padding: 8px 5px;
    margin-top: 2px;
}

.css-19kzrtu{
    padding: 0 !important;
}