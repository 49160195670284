.header{
    position: sticky;
    margin: 0px 10px;
    padding: 5px 0px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: white;
    color: black;
}

.header_info{
    display: flex;
    align-items: center;
}
.logo{
    color: #5f6368;
    font-size: 200px;
    padding-left: 5px;
}
.header_search{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 700px;
    height: 45px;
    border-radius:5px;
    background-color: #f4f4f9;
    color: -internal-light-dark(black, white);

}
.header_search > input{
    border:none;
    outline: none;
    background: transparent;
    width: 100%;
    height: 40px;
}
.header_right{
    display: flex;
    align-items: center;
}