.template{
    background-color: #f4f4f9;
    padding-button: 40px;
    padding-top: 7px;

}
.template-top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 180px;
    margin-right: 180px;
}

.template-right{
    display: flex;
    align-items: center;
}
.gallery-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
}

.template-body{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 160px;
    margin-right: 160px;
    padding: 10px;
}
/* .card{
    margin-left: 20px;
    margin-top: 15px;
    width: 150px;
    height: 200px;
    border: 1px solid grey;
} */